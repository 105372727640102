import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import fgts2 from 'assets/images/fgts2.jpg';
import Success from 'assets/images/success.png';

import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { usePromoterContext } from 'context/promoter/usePromoterContext';

import {
  Container,
  Conditions,
  FormFooter,
  Title,
  Text,
  Row,
  ImgFgts,
  Col,
  TitlePrimary,
  ContainerCaptcha,
} from './styles';
import { ISimulationResult } from './types';
import { TextCenter } from '../ProposalResult/styles';

export const QuestionFgts2 = ({ setWizzardStep }: ISimulationResult) => {
  const { handleSimulate, create, setForm, form } = useSimulationContext();
  const { promoter } = usePromoterContext();
  const [nameBank, setNameBank] = useState('');
  const [nameBankCaixa, setNameBankCaixa] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(true);

  useEffect(() => {
    switch (promoter?.promoterStyles.bank) {
      case '626':
        setNameBank('C6 BANK');
        setNameBankCaixa('C6 CONSIGNADO S.A.');
        break;
      case '149':
        setNameBank('FACTA FINANCEIRA');
        setNameBankCaixa('FACTA FINANCEIRA S.A.');
        break;
      case '623':
        setNameBank('BANCO PAN');
        setNameBankCaixa('BANCO PAN S.A.');
        break;
      case '329':
        setNameBank('ICRED');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-1':
        setNameBank('GRANDINOBANK');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-5':
        setNameBank('GRANDINOBANK');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '324':
        setNameBank('LOTUS MAIS');
        setNameBankCaixa('CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.');
        break;
      case '465':
        setNameBank('QUERO+CRÉDITO');
        setNameBankCaixa('CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.');
        break;
      case '389':
        setNameBank('MERCANTIL DO BRASIL');
        setNameBankCaixa('BANCO MERCANTIL DO BRASIL SA');
        break;
      case '318':
        setNameBank('BMG');
        setNameBankCaixa('BANCO BMG S.A.');
        break;
      case '274':
        setNameBank('NOVO SAQUE');
        setNameBankCaixa('QISTA S.A.');
        break;
      case '329-3':
        setNameBank('PRATA DIGITAL');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-6':
        setNameBank('VCTEX');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-4':
        setNameBank('V8 DIGITAL');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      default:
        setNameBank('SAFRA');
        setNameBankCaixa('J. SAFRA S.A');
        break;
    }
  }, []);

  const setTokenCaptcha = async (tokenCaptcha: any) => {
    if (tokenCaptcha) {
      setForm({
        ...form,
        token: tokenCaptcha,
      });

      setDisabledBtn(false);
    }
  };

  const handleContinue = () => {
    setForm({ ...form });

    const data = {
      id: promoter?.promoterStyles.id as string,
      cpf: form.cpf,
      phone: form.phone,
      birthDate: form.birthDate,
      name: form.name,
      token: form.token ?? '',
      indication: form.indication ?? '',
    };

    handleSimulate(data);
    setWizzardStep(2);
  };

  if (create.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center" noWrap>
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={35} fontWeight={700}>
              Realizando Simulação
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  const content = () => {
    return (
      <Container>
        <Conditions>
          <TextCenter>
            <ImgFgts
              src={Success}
              style={{ marginRight: '5px', boxShadow: 'none', width: '120px' }}
            />
            <TitlePrimary>Falta pouco para você finalizar!</TitlePrimary>
            <Text>{`Autorize o banco ${nameBankCaixa} consultar seu FGTS. Siga os passos abaixo:`}</Text>
          </TextCenter>
        </Conditions>
        <Conditions>
          <Row>
            <Col>
              <ImgFgts src={fgts2} />
            </Col>
            <Col>
              <Title>{`AUTORIZE O BANCO ${nameBankCaixa}`}</Title>
              <Text>{`Autorize o Banco ${nameBankCaixa} a consultar seus dados no aplicativo do FGTS`}</Text>
            </Col>
          </Row>
          <ContainerCaptcha>
            <ReCAPTCHA
              sitekey="6LczAu4dAAAAANxkvoTPgkhuC7HfowUwvxZMO8dV"
              onChange={setTokenCaptcha}
            />
          </ContainerCaptcha>
        </Conditions>
        <FormFooter>
          <Button
            id="btn-simular-novamente"
            type="button"
            label="Simular novamente"
            onClick={handleContinue}
            disabled={disabledBtn}
          />
        </FormFooter>
        <FormFooter>
          <Button
            style={{ background: '#c2c2c2' }}
            type="button"
            label="Sair"
            onClick={() => setWizzardStep(1)}
          />
        </FormFooter>
      </Container>
    );
  };

  return content();
};
